import React from "react";
import { Container, Row, Col } from "reactstrap";
import TeamImage from "../../images/TeamImage.webp";
import QualityService from "../../images/QualityService.webp";
import "./About.css";

const About = () => {
  return (
    <main>
      <Container className="about-container">
        <h1 className="text-center">About Us</h1>
        <Row>
          <Col md="6">
            <img
              src={TeamImage}
              alt="Our dedicated team"
              className="img-fluid border-img"
              width="500px"
              height="333px"
            />
          </Col>
          <Col md="6">
            <h2>We Are You Grow We Cut</h2>
            <p>
              We're more than a lawn care company - we're a team of
              professionals dedicated to bringing beauty and comfort to your
              outdoor spaces. We understand that your yard is an extension of
              your home, and we're here to ensure it always looks its best.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md="6">
            <h2>Our Promise To You</h2>
            <p>
              We promise to deliver top-quality lawn care services, tailored to
              your specific needs. Our team will treat your lawn as if it were
              our own, with the care and attention it deserves. Imagine coming
              home to a beautifully manicured lawn, without lifting a finger.
              That's the peace of mind we provide.
            </p>
          </Col>
          <Col md="6">
            <img
              src={QualityService}
              alt="Delivering quality service"
              className="img-fluid border-img"
              width="500px"
              height="310px"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md="12">
            <h2>Professional Lawn Care, Personal Touch</h2>
            <p>
              At You Grow We Cut, we combine the efficiency of professional lawn
              care with a personal touch. We listen to your preferences,
              understand your needs, and deliver a service that's just right for
              you. Whether it's regular maintenance, landscaping, or pest
              control, we've got you covered.
            </p>
            <p>
              Let us do the hard work, so you can sit back, relax, and enjoy
              your beautiful yard. After all, you deserve to spend more time
              doing what you love. And what's better than a perfectly maintained
              yard without any of the hassle? Contact us now for a free quote,
              and let's bring your dream lawn to life!
            </p>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default About;
