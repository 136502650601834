import React from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Button,
  Row,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import "./PackagePage.css";

const PackagePage = () => {
  return (
    <main>
      <h1 className="text-center mt-4">Our Packages</h1>
      <p className="text-center">
        Choose the package that fits your needs and budget
      </p>
      <p className="text-center disclaimer">
        *Pricing is for lawns up to 1/4 acre. Contact us for a custom quote for
        larger lawns.
      </p>
      <Row className="mt-4">
        <Col md="4">
          <Card className="package-card">
            <CardBody>
              <CardTitle tag="h5">Basic Lawn Care</CardTitle>
              <div className="content-wrapper">
                <CardText>
                  Perfect for keeping your lawn neat and tidy. This package
                  includes:
                  <ul>
                    <li>Mowing</li>
                    <li>Trimming</li>
                    <li>Edging</li>
                  </ul>
                  <strong>$50 per visit</strong>
                </CardText>
                <Link className="link" to="/contact">
                  <Button className="btn-about">Contact</Button>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md="4">
          <Card className="package-card">
            <CardBody>
              <CardTitle tag="h5">Premium Lawn Care</CardTitle>
              <div className="content-wrapper">
                <CardText>
                  A step up for a more plush lawn. Includes everything in the
                  Basic Package, plus:
                  <ul>
                    <li>Fertilization</li>
                    <li>Weed Control</li>
                  </ul>
                  <strong>$75 per visit</strong>
                </CardText>

                <Link className="link" to="/contact">
                  <Button className="btn-about">Us</Button>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col md="4">
          <Card className="package-card">
            <CardBody>
              <CardTitle tag="h5">Complete Lawn Care</CardTitle>
              <div className="content-wrapper">
                <CardText>
                  For those who want the best for their lawn. Includes
                  everything in the Premium Package, plus:
                  <ul>
                    <li>Overseeding</li>
                    <li>Aeration</li>
                    <li>Pest Control</li>
                  </ul>
                  <strong>$100 per visit</strong>
                </CardText>
                <Link className="link" to="/contact">
                  <Button className="btn-about">Today</Button>
                </Link>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <div className="faq">
        <h2 className="text-center mt-4">Frequently Asked Questions</h2>
        <h3>What is included in a visit?</h3>
        <p>
          Our team will provide the services listed in your chosen package,
          including mowing, trimming, edging, fertilization, weed control,
          overseeding, aeration, and/or pest control.
        </p>
        <h3>How often should I schedule visits?</h3>
        <p>
          We recommend weekly or bi-weekly visits for the best results. However,
          we can adjust the schedule based on your specific needs and the
          condition of your lawn.
        </p>
        <h3>What if I'm not satisfied with the services?</h3>
        <p>
          Your satisfaction is our top priority. If you're not satisfied with
          our services, please let us know, and we will make it right.
        </p>
      </div>
    </main>
  );
};

export default PackagePage;
