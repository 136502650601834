import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import TestimonialSlider from "../../components/TestimonialSlider/TestimonialSlider";
import PerfectLawn from "../../images/PerfectLawn.webp";
import Quality from "../../images/Quality.webp";
import Custom from "../../images/Custom.webp";
import "./Home.css";

const Home = () => {
  const [modal, setModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setModal(true);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <main>
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader className="modal-header" toggle={() => setModal(!modal)}>
          YouGrowWeCut.com is For Sale
        </ModalHeader>
        <ModalBody>
          This business is not yet in operation. The site, branding and domain
          (www.YouGrowWeCut.com) are available for sale from{" "}
          <a href="https://www.reactprosolutions.com" target="blank">
            React Pro Solutions
          </a>
          . The site can be customized for the buyer.
        </ModalBody>
      </Modal>
      <Container className="home-container">
        <h1 className="text-center">Welcome to You Grow We Cut!</h1>
        <Row>
          <Col md="6">
            <img
              src={PerfectLawn}
              alt="Perfectly mowed lawn"
              className="img-fluid border-img"
              width="500px"
              height="333px"
            />
          </Col>
          <Col md="6">
            <p>
              We offer a range of lawn care services, including regular
              maintenance, landscaping, and pest control. Our team of
              experienced professionals is dedicated to keeping your lawn
              looking its best all year round.
            </p>
            <p>
              We take pride in our work and value our customers. Check out our
              customer testimonials below to see what they have to say about our
              services.
            </p>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <TestimonialSlider />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col md="6">
            <h2>Quality Landscaping</h2>
            <p>
              Our landscaping services are tailored to meet your unique needs.
              From design to installation, we have you covered.
            </p>
            <img
              src={Quality}
              alt="Close-up of a quality landscaping work"
              className="img-fluid border-img"
              width="500px"
              height="281px"
            />
          </Col>
          <Col md="6">
            <h2>Pest Control</h2>
            <p>
              Don't let pests ruin your beautiful lawn. We offer safe and
              effective pest control solutions.
            </p>
            <img
              src={Custom}
              alt="Hilly freshly cut lawn"
              className="img-fluid border-img"
              width="500px"
              height="334px"
            />
          </Col>
        </Row>

        <Row className="mt-4">
          <Col>
            <Link to="/contact" className="sticky-button">
              <Button className="btn-home">Contact Us Now</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    </main>
  );
};

export default Home;
