import React, { useState } from "react";
import { useForm } from "react-hook-form";
// import emailjs from "emailjs-com";
import "./Contact.css";

const Contact = () => {
  // useEffect(() => {
  //   emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
  // }, []);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    setIsSubmitted(true);
    reset();
  };

  const hasDifferentBillingAddress = watch("hasDifferentBillingAddress");
  const isReferred = watch("isReferred");
  const requiresCustomQuote = watch("requiresCustomQuote");

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="Contact form">
      <label htmlFor="name">
        Name:
        <input
          id="name"
          {...register("name", { required: "Name is required" })}
        />
        {errors.name && <p>{errors.name.message}</p>}
      </label>
      <br />
      <label htmlFor="address">
        Service Address:
        <input
          id="address"
          {...register("address", { required: "Address is required" })}
        />
        {errors.address && <p>{errors.address.message}</p>}
      </label>
      <br />
      <label htmlFor="hasDifferentBillingAddress">
        Is your billing address different?:
        <input
          id="hasDifferentBillingAddress"
          type="checkbox"
          {...register("hasDifferentBillingAddress")}
        />
      </label>
      {hasDifferentBillingAddress && (
        <label htmlFor="billingAddress">
          Billing Address:
          <textarea
            id="billingAddress"
            {...register("billingAddress", {
              required:
                "Billing address is required when different billing address is checked",
            })}
          />
          {errors.billingAddress && <p>{errors.billingAddress.message}</p>}
        </label>
      )}
      <br />
      <label htmlFor="email">
        Email:
        <input
          id="email"
          {...register("email", { required: "Email is required" })}
        />
        {errors.email && <p>{errors.email.message}</p>}
      </label>
      <br />
      <label htmlFor="phone">
        Phone:
        <input
          id="phone"
          {...register("phone", { required: "Phone number is required" })}
        />
        {errors.phone && <p>{errors.phone.message}</p>}
      </label>
      <br />
      <label htmlFor="communication">
        Preferred Method of Communication:
        <select
          id="communication"
          {...register("communication", {
            required: "Communication method is required",
          })}
        >
          <option>Text</option>
          <option>Phone</option>
          <option>Email</option>
        </select>
        {errors.communication && <p>{errors.communication.message}</p>}
      </label>
      <br />
      <label htmlFor="services">
        Do you have a package in mind?:
        <select
          id="services"
          {...register("services", {
            required: "Service selection is required",
          })}
        >
          <option>Not sure</option>
          <option>Basic Lawn Care</option>
          <option>Premium Lawn Care</option>
          <option>Complete Lawn Care</option>
          <option>Custom Package</option>
        </select>
        {errors.services && <p>{errors.services.message}</p>}
      </label>
      <br />
      <label htmlFor="requiresCustomQuote">
        Do you need a custom quote for a larger property?:
        <input
          id="requiresCustomQuote"
          type="checkbox"
          {...register("requiresCustomQuote")}
        />
      </label>

      {requiresCustomQuote && (
        <>
          <label htmlFor="propertySize">
            Property Size (in acres):
            <input
              id="propertySize"
              type="number"
              min="0.25"
              step="0.01"
              {...register("propertySize", {
                required: "Property size is required for custom quote",
              })}
            />
            {errors.propertySize && <p>{errors.propertySize.message}</p>}
          </label>
          <br />
          <label htmlFor="additionalServices">
            Any additional services or specific requirements?:
            <textarea
              id="additionalServices"
              {...register("additionalServices")}
            />
            {errors.additionalServices && (
              <p>{errors.additionalServices.message}</p>
            )}
          </label>
          <br />
        </>
      )}

      <label htmlFor="details">
        Special instructions or property notes?:
        <textarea
          id="details"
          {...register("details", {
            required: "This detail field is required",
          })}
        />
        {errors.details && <p>{errors.details.message}</p>}
      </label>
      <br />
      <label htmlFor="isReferred">
        Were you referred by someone?:
        <input id="isReferred" type="checkbox" {...register("isReferred")} />
      </label>
      {isReferred && (
        <label htmlFor="referrer">
          Who referred you?:
          <input
            id="referrer"
            {...register("referrer", {
              required: "Referrer is required when referred is checked",
            })}
          />
          {errors.referrer && <p>{errors.referrer.message}</p>}
        </label>
      )}
      <br />
      <button type="submit">Submit</button>
      {isSubmitted && (
        <p className="submission-p">
          Thanks, your request has been successfully submitted! You will be
          hearing from us very soon.
        </p>
      )}
    </form>
  );
};

export default Contact;
