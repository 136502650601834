import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import PackagePage from './pages/PackagePage/PackagePage';
import Footer from './components/Footer/Footer';
import Contact from './pages/ContactPage/Contact';

const App = () => {
  return (
    <Router>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="*" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/packages" element={<PackagePage />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
        <Footer />
      
    </Router>
  );
}

export default App;
