import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Nav,
  NavItem,
  NavbarToggler,
  Collapse,
} from "reactstrap";
import "./Header.css";
import Logo from "../../images/Logo.jpg";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <header>
      <Navbar className="navbar" expand="md">
        <div className="brand-and-nav">
          <NavbarBrand tag={RouterNavLink} to="/" className="mx-auto">
            <img className="logo" src={Logo} alt="You Grow We Cut logo" />
          </NavbarBrand>
          <div className="business-name">You Grow We Cut</div>
        </div>
        <NavbarToggler onClick={toggle} aria-label="Toggle navigation" />
        <Collapse isOpen={isOpen} navbar>
          <Nav className="ml-auto" navbar aria-label="Main">
            <NavItem>
              <RouterNavLink
                exact="true"
                to="/"
                className="nav-link"
                aria-label="Home Page"
              >
                Home
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <RouterNavLink
                to="/about"
                className="nav-link"
                aria-label="About Us Page"
              >
                About
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <RouterNavLink
                to="/packages"
                className="nav-link"
                aria-label="Our Packages Page"
              >
                Packages
              </RouterNavLink>
            </NavItem>
            <NavItem>
              <RouterNavLink
                to="/contact"
                className="nav-link"
                aria-label="Contact Us Page"
              >
                Contact
              </RouterNavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
