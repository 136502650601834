const testimonialData = [
  {
    id: 1,
    name: "Alice T.",
    location: "Newman Lake, WA",
    rating: 5,
    comment:
      "Our lawn was in a terrible state after a long winter, but You Grow We Cut had it looking better than ever in just a few visits. Their team is efficient and professional. I highly recommend them!",
  },
  {
    id: 2,
    name: "Bob S.",
    location: "Spokane Valley, WA",
    rating: 5,
    comment:
      "We've been using You Grow We Cut for several years now. They're reliable, friendly, and most importantly, they really know how to take care of a lawn!",
  },
  {
    id: 3,
    name: "Carol L.",
    location: "Otis Orchards, WA",
    rating: 5,
    comment:
      "I hired You Grow We Cut to take care of my lawn and I was not disappointed. My lawn has never looked so good. The team is professional and very easy to work with.",
  },
  {
    id: 4,
    name: "Dave J.",
    location: "Liberty Lake, WA",
    rating: 5,
    comment:
      "You Grow We Cut have been doing an excellent job with our lawn care. They are punctual, professional and our lawns look great. I appreciate their good work.",
  },
  {
    id: 5,
    name: "Emily R.",
    location: "Greenacres, WA",
    rating: 5,
    comment:
      "These guys are the real deal! You Grow We Cut keep my lawn looking pristine. I can’t thank them enough for their excellent service.",
  },
  {
    id: 6,
    name: "Frank P.",
    location: "Post Falls, ID",
    rating: 5,
    comment:
      "You Grow We Cut are great! They've really taken the hassle out of lawn care for me. My yard looks fantastic thanks to them.",
  },
  {
    id: 7,
    name: "Grace B.",
    location: "Mead, WA",
    rating: 5,
    comment:
      "The team at You Grow We Cut is top notch! They are very professional and always leave my lawn looking its best.",
  },
  {
    id: 8,
    name: "Henry M.",
    location: "Spokane, WA",
    rating: 5,
    comment:
      "I've been using You Grow We Cut for the past year and they never disappoint. My lawn is always the envy of the neighborhood.",
  },
  {
    id: 9,
    name: "Irene H.",
    location: "Millwood, WA",
    rating: 5,
    comment:
      "You Grow We Cut is incredible! They transformed our overgrown mess of a lawn into a beautiful outdoor space. Can't thank them enough!",
  },
  {
    id: 10,
    name: "John K.",
    location: "Trentwood, WA",
    rating: 5,
    comment:
      "Best decision I ever made was to hire You Grow We Cut. They've been taking care of my lawn for over a year now, and it has never looked better.",
  },
  {
    id: 11,
    name: "Karen L.",
    location: "Opportunity, WA",
    rating: 5,
    comment:
      "I was so impressed with the work done by You Grow We Cut. Their team was professional and they left my lawn looking immaculate.",
  },
  {
    id: 12,
    name: "Michael P.",
    location: "Dishman, WA",
    rating: 5,
    comment:
      "I've used several lawn care services in the past, but You Grow We Cut is by far the best. Their attention to detail is outstanding and my lawn has never looked healthier!",
  },
  {
    id: 13,
    name: "Nancy O.",
    location: "Veradale, WA",
    rating: 5,
    comment:
      "You Grow We Cut are reliable, thorough, and affordable. They have done an excellent job with our lawn. We couldn't be happier!",
  },
  {
    id: 14,
    name: "Oliver T.",
    location: "Green Bluff, WA",
    rating: 5,
    comment:
      "You Grow We Cut have consistently provided excellent service. Our lawn looks great and we get compliments from our neighbors all the time!",
  },
  {
    id: 15,
    name: "Patricia Q.",
    location: "Coeur d'Alene, ID",
    rating: 5,
    comment:
      "We've been using You Grow We Cut for our lawn maintenance for two years now. They are professional, reliable, and our lawn looks amazing!",
  },
  {
    id: 16,
    name: "Quentin R.",
    location: "Spokane Valley, WA",
    rating: 5,
    comment:
      "I can't recommend You Grow We Cut highly enough. They are prompt, professional, and really know how to keep a lawn looking sharp.",
  },
];
export default testimonialData;
