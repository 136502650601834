import { Container, Row, Col } from "reactstrap";
import { NavLink as RouterNavLink } from "react-router-dom";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
} from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row>
          <Col sm="4" className="text-center">
            <h3>
              <strong>Links</strong>
            </h3>
            <nav>
              <ul className="list-unstyled">
                <li>
                  <RouterNavLink className="gold" to="/">
                    Home
                  </RouterNavLink>
                </li>
                <li>
                  <RouterNavLink className="gold" to="/about">
                    About
                  </RouterNavLink>
                </li>
                <li>
                  <RouterNavLink className="gold" to="/packages">
                    Packages
                  </RouterNavLink>
                </li>

                <li>
                  <RouterNavLink className="gold" to="/contact">
                    Contact
                  </RouterNavLink>
                </li>
              </ul>
            </nav>
          </Col>
          <Col sm="4" className="text-center">
            <h3 className="footer-h3">
              <strong>Social</strong>
            </h3>
            <a
              className="btn btn-social-icon"
              href="https://facebook.com/"
              aria-label="Facebook"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebookF className="footer-icon white" />
            </a>
            <a
              className="btn btn-social-icon"
              href="https://www.twitter.com"
              aria-label="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaTwitter className="footer-icon gold" />
            </a>
            <a
              className="btn btn-social-icon"
              href="https://instagram.com/"
              aria-label="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaInstagram className="footer-icon gold" />
            </a>
            <a
              className="btn btn-social-icon"
              href="https://linkedin.com"
              aria-label="Linkedin"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn className="footer-icon white" />
            </a>
          </Col>
          <Col sm="4" className="text-center">
            <h3 className="footer-h3">
              <strong>Contact</strong>
            </h3>
            <address>
              <a
                role="button"
                className="btn btn-link footer-link"
                href="tel:+15095551212"
                aria-label="Phone"
              >
                <p className="gold"> 1-509-555-1212</p>
              </a>
              <br />
              <a
                role="button"
                className="btn btn-link footer-link"
                href="mailto:test@gmail.com"
                aria-label="Email"
              >
                <p className="gold">YourEmail@gmail.com</p>
              </a>
            </address>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <p className="credit-text">
              Website built by{" "}
              <a
                href="https://www.reactprosolutions.com"
                className="credit"
                target="_blank"
                rel="noopener noreferrer"
              >
                React Pro Solutions LLC
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
